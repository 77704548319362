import * as React from 'react';
import { AxiosError } from 'axios';
import {
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  useMutation,
  useQuery,
} from '@tanstack/react-query';
import { enqueueSnackbar } from '@applift/factor';
import { SortingState } from '@applift/datagrid';

import { WithResponse } from 'models/Response';
import { getBasicCampaignListKey, getCampaignListKey } from 'api/QueryKeys';
import { sortingString } from 'utils/sorting';
import {
  BasicCampaignListApiData,
  CampaignListApiData,
  CampaignListType,
  SaveColListParam,
} from 'models/CampaignList';
import {
  getBasicCampaignList,
  getCampaignList,
  getCampaignListCols,
  getCampaignTypeList,
  saveCampaignListCols,
} from 'api/CampaignList';
import { IoBudgetImpressionInfoType, IODetail } from 'models/IO';
import { queryClient } from 'cache';

type List = WithResponse<CampaignListApiData>;

export const useCampaignList = (
  searchField: string,
  pageSize = 10,
  sorting: SortingState,
  ioIds: number[],
  campaignStatus?: string,
  timezoneId?: number,
  creativeTypeIds?: string,
  campaignIds?: string,
  startDate?: number,
  endDate?: number,
  ioBudgetInfo?: IoBudgetImpressionInfoType,
  ioDetail?: IODetail,
  campaignTypeIds?: number[],
  options?: {
    onSuccess?: UseInfiniteQueryOptions<List>['onSuccess'];
    onError?: UseInfiniteQueryOptions<List>['onError'];
    enabled?: UseInfiniteQueryOptions<List>['enabled'];
  },
): UseInfiniteQueryResult<List> => {
  const sortBy = React.useMemo(() => sortingString(sorting || []).sortBy, [sorting]);

  const queryData = useInfiniteQuery(
    getCampaignListKey.keys({
      scope: 'getCampaignList',
      pageSize,
      searchField,
      sortBy,
      campaignStatus,
      creativeTypeIds,
      ioIds,
      timezoneId,
      campaignIds,
      endDate,
      startDate,
      campaignTypeIds,
    }),
    getCampaignList,
    {
      getNextPageParam: (lastPage, pages) => {
        const totalRecordsFetched = pages.reduce(
          (prev, one) => prev + Number(one.data?.recordsList?.length) ?? 0,
          0,
        );
        if (
          lastPage?.data?.filteredRecords !== undefined &&
          totalRecordsFetched < lastPage?.data?.filteredRecords
        ) {
          return pages.length + 1;
        }
        return null;
      },
      onSuccess: options?.onSuccess,
      select: (data) => {
        const modifiedPages = data.pages.map((page: any) => ({
          ...page,
          data: {
            ...page.data,
            recordsList: page.data?.recordsList?.map((record: CampaignListType) => ({
              ...record,
              ioBudgetInfo,
              ioDetail,
            })),
            recordsTotal: { ...page.data?.recordsTotal, ioBudgetTypeId: ioDetail?.ioBudgetTypeId },
          },
        }));
        return { ...data, pages: modifiedPages };
      },
      onError: (e: AxiosError['response']) => {
        enqueueSnackbar(
          e?.data?.errorObjects
            ? (e.data?.errorObjects[0]?.error as string)
            : 'Something went wrong!',
          {
            variant: 'error',
          },
        );
      },
      enabled: options?.enabled ?? true,
      cacheTime: 0,
    },
  );
  return queryData;
};

type BasicList = WithResponse<BasicCampaignListApiData>;

export const useBasicCampaignList = (
  searchField: string,
  ioIdsList: number[],
  ids?: number[],
  creativeTypeIds?: number[],
  campaignTypeIds?: number[],
  status?: string[],
  options?: {
    onSuccess?: UseInfiniteQueryOptions<BasicList>['onSuccess'];
    onError?: UseInfiniteQueryOptions<BasicList>['onError'];
    enabled?: UseInfiniteQueryOptions<BasicList>['enabled'];
  },
): UseInfiniteQueryResult<BasicList> => {
  const queryData = useInfiniteQuery(
    getBasicCampaignListKey.keys({
      scope: 'getBasicCampaignList',
      searchField,
      ids,
      ioIdsList,
      creativeTypeIds,
      campaignTypeIds,
      status,
      pageNo: -1,
    }),
    getBasicCampaignList,
    {
      getNextPageParam: (lastPage, pages) => {
        const totalRecordsFetched = pages.reduce(
          (prev, one) => prev + Number(one.data?.data?.length) ?? 0,
          0,
        );
        if (
          lastPage?.data?.filteredRecords !== undefined &&
          totalRecordsFetched < lastPage?.data?.filteredRecords
        ) {
          return pages.length + 1;
        }
        return null;
      },
      onSuccess: options?.onSuccess,
      onError: (e: AxiosError['response']) => {
        enqueueSnackbar(
          e?.data?.errorObjects
            ? (e.data?.errorObjects[0]?.error as string)
            : 'Something went wrong!',
          {
            variant: 'error',
          },
        );
      },
      enabled: options?.enabled ?? true,
    },
  );
  return queryData;
};

export const getQueryKey = {
  keys: (args: { scope: string }) => [args],
};

export const getCampaignTypeQueryKey = {
  keys: (args: {
    scope: string;
    ioIds: number[];
    creativeTypeIds?: number[];
    campaignIds?: number[];
    statusList?: string[];
  }) => [args],
};

export const useCampaignListCols = () => {
  const data = useQuery(getQueryKey.keys({ scope: 'getCampaignListCols' }), getCampaignListCols, {
    onError: (e: WithResponse) => {
      enqueueSnackbar(
        e?.errorObjects ? (e.errorObjects[0]?.error as string) : 'Something went wrong!',
        {
          variant: 'error',
        },
      );
    },
    cacheTime: 0,
  });
  return data;
};

export const useSaveColCampaign = (onSaveSuccess?: (info: any) => void) => {
  const mutationResult = useMutation(saveCampaignListCols, {
    mutationKey: ['saveIoColumn'],
    onMutate: (variables: SaveColListParam) => {
      queryClient.setQueryData(getQueryKey.keys({ scope: 'getCampaignListCols' }), () => variables);
    },
    onSuccess: (res) => {
      if (res && onSaveSuccess) {
        onSaveSuccess(res);
      }
    },
  });

  return mutationResult;
};

export interface CampaignTypeParamsType {
  ioIds: number[];
  creativeTypeIds?: number[];
  campaignIds?: number[];
  statusList?: string[];
}

export const useCampaignTypes = (
  params: CampaignTypeParamsType,
  options?: { enabled: boolean },
) => {
  const data = useQuery(
    getCampaignTypeQueryKey.keys({ scope: 'getCampaignTypeList', ...params }),
    getCampaignTypeList,
    {
      cacheTime: 0,
      enabled: options?.enabled,
    },
  );
  return data;
};
