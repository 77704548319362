import * as React from 'react';
import {
  Row,
  Col,
  TextField,
  IconButton,
  Button,
  Chip,
  Tooltip,
  Box,
  Menu,
  MenuItem,
} from '@applift/factor';
import {
  Search,
  Close,
  CaretDown,
  CaretUp,
  Edit,
  Duplicate,
  Delete,
  FileCsv,
  FileXlsx,
} from '@applift/icons';
import {
  RowSelectionState,
  ColumnVisibility,
  VisibilityState,
  SortingState,
} from '@applift/datagrid';
import { useHistory } from 'react-router';
import { OptionId } from 'iqm-framework';

import { useDownloadIoList, useSaveColIo } from 'hooks/useIoList';
import { IoListType } from 'models/IoList';
import { BUDGET_TYPE_ID } from 'constants/apps';
import { IO_STATUS_ID } from 'constants/insertionOrder';
import { DuplicateInsertionOrderDialog } from 'components/DuplicateInsertionOrderDialog';
import { DeleteInsertionOrderDialog } from 'components/DeleteInsertionOrderDialog';
import { Filters } from './component/Filters';
import { colDef } from './colDef';

interface ActionPanelProps {
  search: string;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
  totalCount: number;
  rowSelection: RowSelectionState;
  setRowSelection: React.Dispatch<React.SetStateAction<RowSelectionState>>;
  setShowEndDate: React.Dispatch<React.SetStateAction<boolean>>;
  setShowTotalBudget: React.Dispatch<React.SetStateAction<boolean>>;
  selectedItems: IoListType[];
  isTableLoading: boolean;
  setStatusIds: React.Dispatch<React.SetStateAction<number[]>>;
  statusIds: number[];
  setBudgetTypeIds: React.Dispatch<React.SetStateAction<number[]>>;
  budgetTypeIds: number[];
  columnVisibility: VisibilityState;
  setColumnVisibility: React.Dispatch<React.SetStateAction<VisibilityState>>;
  defaultData: [any[], VisibilityState];
  dateRange: { startDate: Date; endDate: Date } | null;
  timezoneFilter?: OptionId<string> | undefined;
  sorting: SortingState;
  defaultDeselectedColumns: VisibilityState;
}

export const ActionPanel = (props: ActionPanelProps) => {
  const {
    search,
    setSearch,
    totalCount,
    rowSelection,
    selectedItems,
    setRowSelection,
    setShowEndDate,
    setShowTotalBudget,
    isTableLoading,
    setStatusIds,
    statusIds,
    columnVisibility,
    setColumnVisibility,
    defaultData,
    dateRange,
    timezoneFilter,
    sorting,
    defaultDeselectedColumns,
    setBudgetTypeIds,
    budgetTypeIds,
  } = props;

  const [isActionsMenuOpen, setIsActionsMenuOpen] = React.useState(false);
  const [isDuplicateDialogOpen, setIsDuplicateDialogOpen] = React.useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = React.useState(false);
  const [currentDownloadFileType, setCurrentDownloadFileType] = React.useState<
    'xlsx' | 'csv' | null
  >(null);
  const actionsMenuAnchorRef = React.useRef<any>(null);

  const selectedItemsCount = React.useMemo(() => Object.keys(rowSelection).length, [rowSelection]);

  const chipLabel = React.useMemo(() => {
    return `${selectedItemsCount} of ${totalCount} selected`;
  }, [selectedItemsCount, totalCount]);

  const history = useHistory();

  const isAutoSumSelected = React.useMemo(
    () => selectedItems.some((io) => io.isAutoSumIoTotalBudget),
    [selectedItems],
  );

  const isDifferentIoSelected = React.useMemo(
    () =>
      selectedItems.some((io) => io.ioBudgetTypeId === BUDGET_TYPE_ID.DOLLAR_BASED) &&
      selectedItems.some((io) => io.ioBudgetTypeId === BUDGET_TYPE_ID.IMPRESSIONS_BASED),
    [selectedItems],
  );

  const isDeletedSelected = React.useMemo(
    () => !!selectedItems?.find?.((item) => item.ioStatusId === IO_STATUS_ID.DELETED),
    [selectedItems],
  );

  const isOnlyDeletedSelected = React.useMemo(
    () => selectedItems.every((item) => item.ioStatusId === IO_STATUS_ID.DELETED),
    [selectedItems],
  );

  const isExpiredSelected = React.useMemo(
    () => selectedItems.some((item) => item.ioStatusId === IO_STATUS_ID.EXPIRED),
    [selectedItems],
  );

  const isOnlyExpiredSelected = React.useMemo(
    () => selectedItems.every((item) => item.ioStatusId === IO_STATUS_ID.EXPIRED),
    [selectedItems],
  );

  const isActiveSelected = React.useMemo(
    () => selectedItems.some((item) => item.ioStatusId === IO_STATUS_ID.ACTIVE),
    [selectedItems],
  );

  const setTotalBudgetTooltipMsg = React.useMemo(() => {
    if (isDeletedSelected) {
      return `Total Budget can't edited for the Deleted IOs`;
    }
    if (isExpiredSelected) {
      return `Total budget can't be edited for the Expired IOs`;
    }
    if (isDifferentIoSelected) {
      return `Total Budget can't be edited for the different types of IOs`;
    }
    if (isAutoSumSelected) {
      return selectedItems.length === 1
        ? `Total Budget can't be edited as it is fixed to the total campaign budget`
        : `Total budget of the selected IOs can't be edited as one of the IO's total budget is fixed to the total campaign budget`;
    }

    return '';
  }, [
    selectedItems,
    isAutoSumSelected,
    isDifferentIoSelected,
    isDeletedSelected,
    isExpiredSelected,
  ]);

  const disableSetBudgetBtn = !!setTotalBudgetTooltipMsg;

  const endDateButtonTooltip = React.useMemo(() => {
    if (isDeletedSelected) {
      return `End Date can't be edited for the Deleted IO`;
    }

    return '';
  }, [isDeletedSelected]);

  const actionsMenuTooltip = React.useMemo(() => {
    if (isOnlyDeletedSelected) {
      return '';
    }
    if (isDeletedSelected) {
      return `No bulk actions available for the Deleted IOs`;
    }

    return '';
  }, [isDeletedSelected, isOnlyDeletedSelected]);

  const handleActionsMenuSelection = React.useCallback(
    (selection: 'edit' | 'duplicate' | 'delete') => {
      if (selection === 'edit') {
        history.push(`/io-detail/${Object.keys(rowSelection)[0]}`);
      } else if (selection === 'delete') {
        setIsDeleteDialogOpen(true);
      } else {
        setIsDuplicateDialogOpen(true);
      }
    },
    [history, rowSelection],
  );

  const disableDuplicateIOs = React.useMemo(() => {
    return selectedItems?.length !== 1;
  }, [selectedItems]);

  const saveMutation = useSaveColIo();

  const setColumnVisibilityWrapper = (value: any) => {
    const col = value(columnVisibility);
    saveMutation.mutate({
      config: { columnVisibility: col },
    });
    setColumnVisibility(value);
  };

  const onDownloadSuccess = (res: any, payload: any) => {
    const downloadUrl = res.data?.url;
    if (downloadUrl) {
      window.open(downloadUrl);
    }
  };

  const onDownloadSettled = () => {
    setCurrentDownloadFileType(null);
  };

  const downloadMutation = useDownloadIoList({
    onSuccess: onDownloadSuccess,
    onSettled: onDownloadSettled,
  });

  const colList: { label: string; value: string }[] = React.useMemo(() => {
    const list: { label: string; value: string } | [] = [];
    colDef.forEach((data) => {
      // @ts-ignore
      if (data?.columns?.length) {
        // @ts-ignore
        data?.columns?.forEach((row: any) => {
          const title = typeof row.header === 'string' ? row.header : row.meta.headerTitle;
          // @ts-ignore
          list.push({ label: title, value: row.accessorKey });
        });
      } else {
        // @ts-ignore
        list.push({ label: data.header, value: data.accessorKey });
      }
    });
    return list;
  }, []);

  const column = React.useMemo(() => {
    return colList.filter((col) => {
      if (typeof columnVisibility[col?.value] === 'boolean' && columnVisibility[col?.value]) {
        return true;
      }
      if (Object.prototype.hasOwnProperty.call(defaultDeselectedColumns, col?.value)) {
        return false;
      }
      return true;
    });
  }, [columnVisibility, colList, defaultDeselectedColumns]);

  const downloadfile = (fileType: 'xlsx' | 'csv') => {
    downloadMutation.mutate({
      startDate: dateRange?.startDate,
      endDate: dateRange?.endDate,
      timezoneId: timezoneFilter?.id,
      searchField: search,
      sortBy: sorting[0].id,
      order: sorting[0].desc ? 'DESC' : 'ASC',
      fileType,
      columns: column,
    });
  };

  return (
    <>
      <Row xs={{ gutterSize: 0, gutterDirection: 'x' }} sx={{ px: 24, alignItems: 'center' }}>
        {selectedItemsCount > 0 && !isTableLoading && (
          <Col xs={8} sx={{ display: 'flex', alignItems: 'center' }}>
            <Chip
              size="small"
              label={chipLabel}
              color="secondary"
              onDelete={() => {
                setRowSelection({});
              }}
            />
            <>
              <Tooltip arrow placement="top" title={actionsMenuTooltip}>
                <Box sx={{ display: 'inline-flex' }}>
                  <Button
                    size="small"
                    color="primary"
                    variant="outlined"
                    endIcon={isActionsMenuOpen ? <CaretUp /> : <CaretDown />}
                    ref={actionsMenuAnchorRef}
                    onClick={() => setIsActionsMenuOpen(true)}
                    sx={{ ml: 8 }}
                    disabled={isDeletedSelected && !isOnlyDeletedSelected}
                  >
                    Actions
                  </Button>
                </Box>
              </Tooltip>
              <Menu
                anchorEl={actionsMenuAnchorRef.current}
                open={isActionsMenuOpen}
                onClose={() => setIsActionsMenuOpen(false)}
                sx={{ mt: 4 }}
                PaperProps={{
                  sx: { borderColor: 'primary-600' },
                  variant: 'outlined',
                }}
              >
                <MenuItem
                  disabled={selectedItemsCount !== 1 || isOnlyDeletedSelected}
                  onClick={() => {
                    handleActionsMenuSelection('edit');
                    setIsActionsMenuOpen(false);
                  }}
                  sx={{ pr: 32 }}
                >
                  <Edit sx={{ mr: 8, textColor: 'neutral-400' }} /> Edit
                </MenuItem>
                <MenuItem
                  disabled={disableDuplicateIOs}
                  onClick={() => {
                    handleActionsMenuSelection('duplicate');
                    setIsActionsMenuOpen(false);
                  }}
                  sx={{ pr: 32 }}
                >
                  <Duplicate sx={{ mr: 8, textColor: 'neutral-400' }} /> Duplicate
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleActionsMenuSelection('delete');
                    setIsActionsMenuOpen(false);
                  }}
                  disabled={isOnlyDeletedSelected}
                  sx={{ pr: 32 }}
                >
                  <Delete
                    sx={{
                      textColor: 'neutral-400',
                      fontSize: 24,
                      position: 'relative',
                    }}
                    style={{ left: -3, marginRight: 6 }}
                  />{' '}
                  Delete
                </MenuItem>
              </Menu>
            </>
            {!isOnlyDeletedSelected && !isOnlyExpiredSelected && isActiveSelected && (
              <Tooltip
                arrow
                placement="top"
                title={setTotalBudgetTooltipMsg}
                disableHoverListener={!disableSetBudgetBtn}
              >
                <Box sx={{ display: 'inline-flex' }}>
                  <Button
                    size="small"
                    color="primary"
                    variant="outlined"
                    disabled={disableSetBudgetBtn}
                    sx={{ marginLeft: 8 }}
                    onClick={() => setShowTotalBudget(true)}
                  >
                    Set Total Budget
                  </Button>
                </Box>
              </Tooltip>
            )}
            {!isOnlyDeletedSelected && (
              <Tooltip arrow placement="top" title={endDateButtonTooltip}>
                <Box sx={{ display: 'inline-flex' }}>
                  <Button
                    size="small"
                    color="primary"
                    variant="outlined"
                    sx={{ marginLeft: 8 }}
                    onClick={() => setShowEndDate(true)}
                    disabled={isDeletedSelected}
                  >
                    Set End Date
                  </Button>
                </Box>
              </Tooltip>
            )}
          </Col>
        )}
        <Col
          xs={4}
          sx={{
            display: 'flex',
            justifyContent: 'end',
            alignItems: 'center',
            ml: 'auto',
            my: 16,
          }}
        >
          <TextField
            type="text"
            value={search}
            onChange={(e: any) => {
              setSearch(e.target.value);
            }}
            placeholder="Search by ID, Name"
            variant="outlinedDash"
            InputProps={{
              startAdornment: <Search sx={{ textColor: 'neutral-400' }} />,
              endAdornment:
                search?.length > 0 ? (
                  <IconButton
                    onClick={() => {
                      setSearch('');
                    }}
                    size="small"
                  >
                    <Close fontSize={16} />
                  </IconButton>
                ) : null,
            }}
          />
          <Tooltip title="Download CSV" placement="top" arrow>
            <Box>
              <IconButton
                color="secondary"
                disabled={currentDownloadFileType === 'csv' && downloadMutation.isLoading}
                onClick={(e) => {
                  setCurrentDownloadFileType('csv');
                  downloadfile('csv');
                }}
              >
                <FileCsv fontSize={24} />
              </IconButton>
            </Box>
          </Tooltip>
          <Tooltip title="Download XLSX" placement="top" arrow>
            <Box>
              <IconButton
                color="secondary"
                disabled={currentDownloadFileType === 'xlsx' && downloadMutation.isLoading}
                onClick={(e) => {
                  setCurrentDownloadFileType('xlsx');
                  downloadfile('xlsx');
                }}
              >
                <FileXlsx fontSize={24} />
              </IconButton>
            </Box>
          </Tooltip>
          <Filters
            setStatusIds={setStatusIds}
            statusIds={statusIds}
            setBudgetTypeIds={setBudgetTypeIds}
            budgetTypeIds={budgetTypeIds}
          />
          <ColumnVisibility
            data={defaultData[0]}
            onChange={setColumnVisibilityWrapper}
            value={columnVisibility}
            slotProps={{
              SelectAdvancePopoverProps: {
                slotProps: {
                  // @ts-ignore
                  PopperProps: {
                    anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
                    transformOrigin: { vertical: 'top', horizontal: 'right' },
                  },
                },
              },
              IconButtonProps: { color: 'secondary' },
            }}
          />
        </Col>
      </Row>
      {isDuplicateDialogOpen && (
        <DuplicateInsertionOrderDialog
          insertionOrder={selectedItems[0]}
          onClose={() => {
            setIsDuplicateDialogOpen(false);
          }}
          onDuplicateSuccess={() => {
            setIsActionsMenuOpen(false);
            setRowSelection({});
          }}
        />
      )}
      {isDeleteDialogOpen && (
        <DeleteInsertionOrderDialog
          selectedInsertionOrders={selectedItems}
          onClose={() => setIsDeleteDialogOpen(false)}
          onDeleteSuccess={() => {
            setRowSelection({});
          }}
        />
      )}
    </>
  );
};
