import * as React from 'react';
import { AxiosError } from 'axios';
import {
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  useMutation,
  useQuery,
} from '@tanstack/react-query';
import { enqueueSnackbar } from '@applift/factor';
import { SortingState } from '@applift/datagrid';
import { WithResponse } from 'models/Response';
import { BasicIoListApiData, IoListApiData } from 'models/IoList';
import { getIoListKey, getBasicIoListKey, getIOBudgetTypesKey } from 'api/QueryKeys';
import {
  getIoList,
  getIoBasicList,
  getIoListCols,
  saveIoListCols,
  downloadIoList,
  getIOBudgetTypes,
} from 'api/IoList';
import { sortingString } from 'utils/sorting';

type List = WithResponse<IoListApiData>;

export const useIoList = (
  searchField: string,
  pageSize = 10,
  sorting?: SortingState,
  ioStatusIds?: string,
  dateRange?: { startDate: Date; endDate: Date } | null,
  timezoneId?: number,
  budgetTypeIds?: string,
  options?: {
    onSuccess?: UseInfiniteQueryOptions<List>['onSuccess'];
    onError?: UseInfiniteQueryOptions<List>['onError'];
    enabled?: UseInfiniteQueryOptions<List>['enabled'];
  },
): UseInfiniteQueryResult<List> => {
  const sortBy = React.useMemo(() => sortingString(sorting || []).sortBy, [sorting]);
  const startDate = dateRange?.startDate;
  const endDate = dateRange?.endDate;
  const timeZoneId = timezoneId;

  const queryData = useInfiniteQuery(
    getIoListKey.keys({
      scope: 'getIoList',
      pageSize,
      searchField,
      sortBy,
      ioStatusIds,
      startDate,
      endDate,
      timeZoneId,
      budgetTypeIds,
    }),
    getIoList,
    {
      getNextPageParam: (lastPage, pages) => {
        const totalRecordsFetched = pages.reduce((prev, one) => {
          const datalength = one.data?.recordsList?.length;
          if (datalength) {
            return prev + datalength;
          }
          return prev;
        }, 0);
        if (
          lastPage?.data?.filteredRecords !== undefined &&
          totalRecordsFetched < lastPage?.data?.filteredRecords
        ) {
          return pages.length + 1;
        }
        return null;
      },
      onSuccess: options?.onSuccess,
      onError: (e: AxiosError['response']) => {
        enqueueSnackbar(
          e?.data?.errorObjects
            ? (e.data?.errorObjects[0]?.error as string)
            : 'Something went wrong!',
          {
            variant: 'error',
          },
        );
      },
      enabled: options?.enabled ?? true,
      cacheTime: 0,
    },
  );
  return queryData;
};

type BasicList = WithResponse<BasicIoListApiData>;

export const useIoBasicList = (
  searchField: string,
  ioIdList?: number[],
  sorting?: SortingState,
  ioStatusIdsList?: number[],
  ioBudgetTypeIdsList?: number[],
  options?: {
    onSuccess?: UseInfiniteQueryOptions<BasicList>['onSuccess'];
    onError?: UseInfiniteQueryOptions<BasicList>['onError'];
    enabled?: UseInfiniteQueryOptions<BasicList>['enabled'];
  },
): UseInfiniteQueryResult<BasicList> => {
  const sortBy = React.useMemo(() => sortingString(sorting || []).sortBy, [sorting]);

  const queryData = useInfiniteQuery(
    getBasicIoListKey.keys({
      scope: 'getIoList',
      searchField,
      pageNo: -1,
      ioIdList,
      sortBy,
      ioStatusIdsList,
      ioBudgetTypeIdsList,
    }),
    getIoBasicList,
    {
      getNextPageParam: (lastPage, pages) => {
        const totalRecordsFetched = pages.reduce(
          (prev, one) => prev + Number(one.data?.ioBasicDetailsList?.length) ?? 0,
          0,
        );
        if (
          lastPage?.data?.filteredRecords !== undefined &&
          totalRecordsFetched < lastPage?.data?.filteredRecords
        ) {
          return pages.length + 1;
        }
        return null;
      },
      onSuccess: options?.onSuccess,
      onError: (e: AxiosError['response']) => {
        enqueueSnackbar(
          e?.data?.errorObjects
            ? (e.data?.errorObjects[0]?.error as string)
            : 'Something went wrong!',
          {
            variant: 'error',
          },
        );
      },
      enabled: options?.enabled ?? true,
    },
  );
  return queryData;
};

export const getQueryKey = {
  keys: (args: { scope: string }) => [args],
};

export const useIoListCols = () => {
  const data = useQuery(getQueryKey.keys({ scope: 'getIoListCols' }), getIoListCols, {
    onError: (e: WithResponse) => {
      enqueueSnackbar(
        e?.errorObjects ? (e.errorObjects[0]?.error as string) : 'Something went wrong!',
        {
          variant: 'error',
        },
      );
    },
    cacheTime: 0,
  });
  return data;
};

export const useSaveColIo = (onSaveSuccess?: (info: any) => void) => {
  const mutationResult = useMutation(saveIoListCols, {
    mutationKey: ['saveCampaignColumn'],
    onSuccess: (res) => {
      if (res && onSaveSuccess) {
        onSaveSuccess(res);
      }
    },
  });

  return mutationResult;
};

export const useDownloadIoList = (options?: {
  onSuccess?: (res: any, payload: any) => void;
  onError?: (props: any) => void;
  onSettled?: (props: any) => void;
  enabled?: UseInfiniteQueryOptions<BasicList>['enabled'];
}) => {
  const mutationResult = useMutation(downloadIoList, {
    mutationKey: ['downloadIoList'],
    onSuccess: options?.onSuccess,
    onError: (e) => {
      enqueueSnackbar('Something went wrong. Please try after some time.', {
        variant: 'error',
      });
      if (options?.onError) {
        options.onError(e);
      }
    },
    onSettled: options?.onSettled,
  });

  return mutationResult;
};

export const useIoBudgetTypesList = () => {
  return useQuery(getIOBudgetTypesKey.keys('getIOBudgetTypes'), getIOBudgetTypes, {
    staleTime: Infinity,
  });
};
