import * as React from 'react';
import { ColumnDef } from '@applift/datagrid';
import { InfoCircle } from '@applift/icons';
import { Box, Tooltip, Typography, sx } from '@applift/factor';
import moment from 'moment';

import { DataDogLogger } from 'services/DataDog';
import {
  FormattedNumberCell,
  RawDataCell,
  CurrencyCell,
  DateCell,
  TimezoneCell,
  PacingCellWithTime,
  PacingCell,
} from 'components/CellType';
import { PacingInfoTooltip } from 'components/PacingInfoTooltip';
import { PercentCell } from 'components/CellType/PercentCell';
import { formatNumberWithDecimal, numberFormat } from 'utils/format';
import { IconTextCell } from 'components/CellType/IconTextCell';
import { HourCell } from 'components/CellType/HourCell';
import { CreativesCell } from 'components/CellType/CreativesCell';
import { BUDGET_TYPE_ID } from 'constants/apps';
import {
  CAMPAIGN_STATUSES,
  CAMPAIGN_TYPE_BY_ID,
  CAMPAIGN_TYPE_BY_NAME,
} from 'constants/campaignList';
import { CampaignListType } from 'models/CampaignList';
import { IODetail } from 'models/IO';
import { MAX_PRIORITY, MIN_PRIORITY } from 'constants/advancedModelling';
import { CampaignNameCell, EditCampaignPriorityCell } from './CellType';
import { CampaignPriorityCell } from './CellType/CampaignPriorityCell';

export const getCampaignsTableColDef = (ioDetail?: IODetail): ColumnDef<any>[] => [
  {
    accessorKey: 'campaignId',
    header: 'ID',
    id: 'campaignId',
    size: 80,
    cell: (props) => <RawDataCell value={`${props.renderValue()}`} />,
    meta: {
      description: 'Campaign ID',
      excludeColumnFromColumnVisibility: true,
    },
  },
  {
    accessorKey: 'campaignName',
    id: 'campaignName',
    header: 'Campaign Name',
    size: 260,
    cell: (props) => <CampaignNameCell rowData={props.row?.original} />,
    meta: {
      description: 'Campaign Name',
      excludeColumnFromColumnVisibility: true,
    },
  },
  {
    id: 'campaignSpecifics',
    header: 'Campaign Specifics',
    columns: [
      {
        accessorKey: 'campaignPriority',
        header: () => (
          <Box
            sx={{ width: 100, display: 'flex', justifyContent: 'between', alignItems: 'center' }}
          >
            <Typography component="div" sx={{ mr: 8 }}>
              Priority
              <br />
              <Typography weight="normal">(1-10)</Typography>
            </Typography>
            <Tooltip
              arrow
              placement="top-start"
              title="To maximize the impact of your campaign priority, it is recommended to allocate an IO budget of a minimum of $1000/1M impressions, along with a minimum duration of 7 days."
            >
              <InfoCircle fontSize={24} sx={{ textColor: 'neutral-400' }} />
            </Tooltip>
          </Box>
        ),
        id: 'campaignPriority',
        size: 120,
        cell: (props) => <CampaignPriorityCell rowData={props.row.original} ioDetail={ioDetail} />,
        meta: {
          headerTitle: 'Priority',
          align: 'right',
          editable: true,
          valueSetter: (params) => ({
            ...params.row,
            campaignPriority: params.value ? Number.parseInt(params.value, 10) : null,
          }),
          preProcessEditCellProps: (params) => {
            let error = '';

            if (!params.props?.value) {
              return { ...params.props, error };
            }

            const value = parseInt(params?.props?.value, 10);

            if (value < MIN_PRIORITY || value > MAX_PRIORITY) {
              error = 'Priority must be in the range of 1-10';
            }
            return {
              ...params.props,
              error,
              value:
                typeof params.props.value === 'string'
                  ? params.props.value.replaceAll(/\D/g, '')
                  : params.props.value,
            };
          },
          renderEditCell: (props) => {
            // eslint-disable-next-line
            return <EditCampaignPriorityCell {...props} />;
          },
        },
      },
      {
        accessorKey: 'status',
        id: 'status',
        header: 'Status',
        size: 150,
        cell: (props) => (
          <IconTextCell
            iconName={props.renderValue() as string}
            label={props.renderValue() as string}
            iconSize={20}
          />
        ),
        meta: {
          description: 'Campaign Status',
        },
      },
      {
        accessorKey: 'creativesCount',
        id: 'creativesCount',
        header: 'Creatives',
        size: 100,
        cell: (props) => (
          <CreativesCell
            rumActionName="Open Creative List Dialog"
            campaignData={props.row?.original as CampaignListType}
            rumLogClick={DataDogLogger.IODetailsPage.viewCreativesByCampaign}
          />
        ),
        footer: () => <Typography weight="demi">—</Typography>,
        meta: {
          align: 'right',
          description: 'Number of creatives attached to the campaign',
        },
      },
      {
        accessorKey: 'campaignTimezone',
        id: 'campaignTimezone',
        header: 'Time Zone',
        cell: (props) => <TimezoneCell timezoneName={`${props.renderValue()}`} />,
        size: 120,
        meta: {
          align: 'left',
          description: 'Campaign Time Zone',
        },
      },
      {
        accessorKey: 'startTime',
        id: 'startTime',
        header: 'Start Date',
        cell: (props) => (
          <DateCell
            date={(props.renderValue() as number) * 1000}
            timezoneId={props.row.original.campaignTimezone}
            isTimezoneNameProvided
          />
        ),
        size: 140,
        footer: () => <Typography weight="demi">—</Typography>,
        meta: {
          align: 'right',
          description: 'Campaign Start Date',
        },
      },
      {
        accessorKey: 'endTime',
        id: 'endTime',
        header: 'End Date',
        cell: (props) => (
          <DateCell
            date={(props.renderValue() as number) * 1000}
            timezoneId={props.row.original.campaignTimezone}
            isTimezoneNameProvided
          />
        ),
        size: 140,
        footer: () => <Typography weight="demi">—</Typography>,
        meta: {
          align: 'right',
          description: 'Campaign End Date',
        },
      },
      {
        accessorKey: 'campaignTypeId',
        id: 'campaignTypeId',
        header: 'Campaign Type',
        size: 150,
        footer: () => null,
        enableSorting: false,
        cell: (props) => {
          const iconName = props.renderValue() === 1 ? 'CampaignAdvanced' : 'CampaignPG';
          return (
            <IconTextCell
              iconName={iconName}
              label={CAMPAIGN_TYPE_BY_ID[props.renderValue() as '1' | '2']}
              iconSize={20}
            />
          );
        },
      },
    ],
  },
  {
    id: 'budget',
    header: 'Budget',
    columns: [
      {
        accessorKey: 'maxBid',
        id: 'maxBid',
        header: 'Max Bid Price',
        size: 140,
        cell: (props) => <CurrencyCell value={props.renderValue() as number} />,
        footer: () => <Typography weight="demi">—</Typography>,
        meta: {
          align: 'right',
          description:
            'The maximum bidding price limit acts as the upper limit for any adjusted bid price, if applicable.',
        },
      },
      {
        accessorKey: 'budgetTotal',
        id: 'budgetTotal',
        header: 'Total Budget',
        cell: (props) => {
          const rowData = props.row?.original;
          return (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'end',
                width: 100,
              }}
            >
              {props.row.original.budgetTypeId === BUDGET_TYPE_ID.DOLLAR_BASED ? (
                <Typography sx={{ marginRight: 2 }}>$</Typography>
              ) : (
                <Typography sx={{ marginRight: 2 }}>Imps</Typography>
              )}
              <CurrencyCell
                value={
                  rowData.budgetTypeId === BUDGET_TYPE_ID.DOLLAR_BASED
                    ? formatNumberWithDecimal((props.renderValue() as number) || 0)
                    : (numberFormat(rowData.targetImpression) as any) || 0
                }
                formatCurrencyValue={false}
              />
            </Box>
          );
        },

        footer: (props) => {
          const rowData = props.row?.original;
          const budgetTypeId = rowData.ioBudgetTypeId;
          return (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'end',
                width: 100,
              }}
            >
              {budgetTypeId === BUDGET_TYPE_ID.DOLLAR_BASED ? (
                <Typography sx={{ marginRight: 2 }}>$</Typography>
              ) : (
                <Typography sx={{ marginRight: 2 }}>Imps</Typography>
              )}
              <CurrencyCell
                isFooterCell
                value={
                  budgetTypeId === BUDGET_TYPE_ID.DOLLAR_BASED
                    ? formatNumberWithDecimal((props.renderValue?.() as number) ?? 0)
                    : (numberFormat(rowData.targetImpression) as any) ?? 0
                }
                formatCurrencyValue={false}
              />
            </Box>
          );
        },
        size: 140,
        meta: {
          align: 'right',
          description:
            'The amount set as total spending limit including media cost, data cost and pre-bid cost',
        },
      },
      {
        accessorKey: 'budgetDay',
        id: 'budgetDay',
        cell: (props) => {
          const rowData = props.row?.original;
          const isPgCampaign = rowData.campaignTypeId === CAMPAIGN_TYPE_BY_NAME.pg;
          if (isPgCampaign) {
            return (
              <Tooltip
                title="Daily Budget is not applicable for PG Campaigns"
                placement="top"
                arrow
              >
                <Typography>—</Typography>
              </Tooltip>
            );
          }

          return (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'end',
                width: 100,
              }}
            >
              {rowData.budgetTypeId === BUDGET_TYPE_ID.DOLLAR_BASED ? (
                <Typography sx={{ marginRight: 2 }}>$</Typography>
              ) : (
                <Typography sx={{ marginRight: 2 }}>Imps</Typography>
              )}
              <CurrencyCell
                value={
                  rowData.budgetTypeId === BUDGET_TYPE_ID.DOLLAR_BASED
                    ? formatNumberWithDecimal((props.renderValue() as number) ?? 0)
                    : (numberFormat(rowData.dailyImpression) as any) ?? 0
                }
                formatCurrencyValue={false}
              />
            </Box>
          );
        },
        footer: (props) => {
          const rowData = props.row?.original;
          const budgetTypeId = rowData.ioBudgetTypeId;
          return (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'end',
                width: 100,
              }}
            >
              {budgetTypeId === BUDGET_TYPE_ID.DOLLAR_BASED ? (
                <Typography sx={{ marginRight: 2 }}>$</Typography>
              ) : (
                <Typography sx={{ marginRight: 2 }}>Imps</Typography>
              )}
              <CurrencyCell
                isFooterCell
                value={
                  budgetTypeId === BUDGET_TYPE_ID.DOLLAR_BASED
                    ? formatNumberWithDecimal((props.renderValue?.() as number) ?? 0)
                    : (numberFormat(rowData.dailyImpression) as any) ?? 0
                }
                formatCurrencyValue={false}
              />
            </Box>
          );
        },
        header: 'Daily Budget',
        size: 140,
        meta: {
          align: 'right',
          description:
            'The amount set as daily spending limit including media cost, data cost and pre-bid cost',
        },
      },
      {
        accessorKey: 'pacingPercentage',
        id: 'pacingPercentage',
        header: () => (
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            Campaign Pacing
            <Tooltip
              classes={{ tooltip: sx({ p: 0 }) }}
              title={() => {
                return (
                  <PacingInfoTooltip
                    title="Campaign Pacing"
                    linkHref="https://help.iqm.com/en/articles/8672915-a-guide-to-io-pacing-campaign-pacing-daily-pacing-in-iqm-s-platform"
                  />
                );
              }}
              placement="right"
              arrow
            >
              <InfoCircle fontSize={24} sx={{ ml: 8 }} />
            </Tooltip>
          </Box>
        ),
        footer: () => <Typography weight="demi">—</Typography>,
        meta: {
          align: 'right',
          headerTitle: 'Campaign Pacing',
        },
        cell: (props) => {
          const rowData = props.row?.original;
          const { budgetTypeId } = rowData;

          const currentDate = moment().valueOf();
          const startDate = rowData.startTime * 1000;

          const isStartDateInFuture = startDate > currentDate;

          const showPacingCell = () => {
            if (isStartDateInFuture) {
              return false;
            }
            if (
              rowData.status === CAMPAIGN_STATUSES.DRAFT ||
              rowData.status === CAMPAIGN_STATUSES.PENDING ||
              rowData.status === CAMPAIGN_STATUSES.REJECTED
            ) {
              return false;
            }
            if (rowData.pacingPercentage === null) {
              return false;
            }
            return true;
          };

          if (rowData.campaignTypeId === CAMPAIGN_TYPE_BY_NAME.pg) {
            return (
              <Tooltip title="Campaign pacing is not applicable for PG campaigns">
                <Typography>—</Typography>
              </Tooltip>
            );
          }

          return showPacingCell() ? (
            <PacingCellWithTime
              budgetCompletionPercentage={rowData.pacingPercentage ?? 0}
              actualSpent={rowData.actualSpent}
              expectedSpent={rowData.expectedSpent}
              remainingDuration={rowData.remainingDuration}
              campaignDuration={rowData.campaignDuration}
              budgetTypeId={budgetTypeId}
              startDate={rowData.startTime * 1000} // converting sec to milli sec
              endDate={rowData.endTime * 1000} // converting sec to milli sec
              totalBudget={
                budgetTypeId === BUDGET_TYPE_ID.IMPRESSIONS_BASED
                  ? rowData.targetImpression
                  : rowData.budgetTotal
              }
              status={rowData.status}
            />
          ) : (
            <>—</>
          );
        },
        size: 300,
        minSize: 240,
      },
      {
        accessorKey: 'dailyPacingPercentage',
        id: 'dailyPacingPercentage',
        header: () => (
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            Daily Pacing
            <Tooltip
              classes={{ tooltip: sx({ p: 0 }) }}
              title={() => {
                return (
                  <PacingInfoTooltip
                    title="Daily Pacing"
                    linkHref="https://help.iqm.com/en/articles/8672915-a-guide-to-io-pacing-campaign-pacing-daily-pacing-in-iqm-s-platform"
                  />
                );
              }}
              placement="right"
              arrow
            >
              <InfoCircle fontSize={24} sx={{ ml: 8 }} />
            </Tooltip>
          </Box>
        ),
        footer: () => <Typography weight="demi">—</Typography>,
        meta: {
          align: 'right',
          headerTitle: 'Daily Pacing',
        },
        cell: (props) => {
          const rowData = props.row?.original;
          const { budgetTypeId } = rowData;

          const currentDate = moment().valueOf();
          const startDate = rowData.startTime * 1000;

          const isStartDateInFuture = startDate > currentDate;

          if (rowData.campaignTypeId === CAMPAIGN_TYPE_BY_NAME.pg) {
            return (
              <Tooltip title="Daily pacing is not applicable for PG campaigns">
                <Typography>—</Typography>
              </Tooltip>
            );
          }

          const showPacingCell = () => {
            if (isStartDateInFuture) {
              return false;
            }
            if (
              rowData.status === CAMPAIGN_STATUSES.DRAFT ||
              rowData.status === CAMPAIGN_STATUSES.PENDING ||
              rowData.status === CAMPAIGN_STATUSES.DELETED ||
              rowData.status === CAMPAIGN_STATUSES.REJECTED
            ) {
              return false;
            }
            if (rowData.dailyPacingPercentage === null) {
              return null;
            }
            return true;
          };

          return showPacingCell() ? (
            <PacingCell
              budgetCompletionPercentage={rowData.dailyPacingPercentage ?? 0}
              actualSpent={rowData.actualDailySpent}
              expectedSpent={rowData.expectedDailySpent}
              dailyBudget={
                budgetTypeId === BUDGET_TYPE_ID.DOLLAR_BASED
                  ? rowData.budgetDay
                  : rowData.dailyImpression
              }
              budgetTypeId={budgetTypeId}
              totalDuration={rowData.dailyCampaignDuration}
              remainingDuration={rowData.dailyRemainingDuration}
            />
          ) : (
            <>—</>
          );
        },
        size: 300,
        minSize: 240,
      },
      {
        accessorKey: 'organizationBidShadingSaving',
        id: 'organizationBidShadingSaving',
        header: 'Bid Shading Savings',
        size: 200,
        cell: (props) => <CurrencyCell value={props.renderValue() as number} />,
        // @ts-ignore
        footer: (props) => <CurrencyCell value={props.renderValue() as number} />,
        meta: {
          headerTitle: 'Bid Shading Savings ($)',
          align: 'right',
          description:
            'Amount saved in programmatic ad buying auction after bidding with the optimal bid price.',
        },
      },
    ],
  },
  {
    id: 'performance',
    header: 'Performance',
    columns: [
      {
        accessorKey: 'impressions',
        id: 'impressions',
        header: 'Impressions',
        cell: (props) => <FormattedNumberCell value={props.renderValue() as number} />,
        footer: (props) => (
          // @ts-ignore
          <FormattedNumberCell isFooterCell value={props.renderValue() as number} />
        ),
        size: 140,
        meta: {
          align: 'right',
          description: 'Number of times ad displayed on someone’s screen',
        },
      },
      {
        accessorKey: 'clicks',
        id: 'clicks',
        header: 'Clicks',
        cell: (props) => <FormattedNumberCell value={props.renderValue() as number} />,
        footer: (props) => (
          // @ts-ignore
          <FormattedNumberCell isFooterCell value={props.renderValue() as number} />
        ),
        size: 100,
        meta: {
          align: 'right',
          description: 'Number of times someone clicks your ad',
        },
      },
      {
        accessorKey: 'reach',
        id: 'reach',
        header: 'Reach',
        cell: (props) => <FormattedNumberCell value={props.renderValue() as number} />,
        footer: (props) => (
          // @ts-ignore
          <FormattedNumberCell isFooterCell value={props.renderValue() as number} />
        ),
        size: 100,
        meta: {
          align: 'right',
          description: 'Reach',
        },
      },
      {
        accessorKey: 'frequency',
        id: 'frequency',
        header: 'Frequency',
        cell: (props) => <FormattedNumberCell value={props.renderValue() as number} />,
        footer: (props) => (
          // @ts-ignore
          <FormattedNumberCell isFooterCell value={props.renderValue() as number} />
        ),
        size: 140,
        meta: {
          align: 'right',
          description: 'Frequency',
        },
      },
    ],
  },
  {
    id: 'rates',
    header: 'Rates',
    columns: [
      {
        accessorKey: 'CTR',
        id: 'CTR',
        header: 'CTR',
        size: 100,
        cell: (props) => <PercentCell value={props.renderValue() as number} />,
        footer: (props) => (
          // @ts-ignore
          <PercentCell isFooterCell value={props.renderValue() as number} />
        ),
        meta: {
          align: 'right',
          description:
            'Click-through rate is the percentage of Clicks received per the Impressions served',
        },
      },
      {
        accessorKey: 'winRate',
        id: 'winRate',
        header: 'Win Rate',
        cell: (props) => <PercentCell value={props.renderValue() as number} />,
        footer: (props) => (
          // @ts-ignore
          <PercentCell isFooterCell value={props.renderValue() as number} />
        ),
        size: 100,
        meta: {
          align: 'right',
          description: 'Percentage of Impressions won per the Bids made',
        },
      },
      {
        accessorKey: 'VCR',
        id: 'VCR',
        header: 'VCR',
        cell: (props) => <PercentCell value={props.renderValue() as number} />,
        footer: (props) => (
          // @ts-ignore
          <PercentCell isFooterCell value={props.renderValue() as number} />
        ),
        size: 100,
        meta: {
          align: 'right',
          description:
            'Video Completion Rate is the percentage of Video ads that play through to completion',
        },
      },
    ],
  },
  {
    id: 'spending',
    header: 'Spending',
    columns: [
      {
        accessorKey: 'spent',
        id: 'spent',
        header: 'Total Spent',
        cell: (props) => <CurrencyCell value={props.renderValue() as number} />,
        footer: (props) => (
          // @ts-ignore
          <CurrencyCell isFooterCell value={props.renderValue() as number} />
        ),
        size: 140,
        meta: {
          align: 'right',
          description:
            'Total spent is the amount spent by the campaign(s) for the selected timeframe, that includes the media cost, data cost and pre-bid cost',
          headerTitle: 'Total Spent ($)',
        },
      },

      {
        accessorKey: 'dataCost',
        id: 'dataCost',
        cell: (props) => <CurrencyCell value={props.renderValue() as number} />,
        footer: (props) => (
          // @ts-ignore
          <CurrencyCell isFooterCell value={props.renderValue() as number} />
        ),
        header: 'Data Cost',
        size: 140,
        meta: {
          align: 'right',
          description: 'Amount spent to serve Impressions to Audience you matched or purchased',
          headerTitle: 'Data Cost ($)',
        },
      },
      {
        accessorKey: 'mediaSpent',
        id: 'mediaSpent',
        header: 'Media Cost',
        cell: (props) => <CurrencyCell value={props.renderValue() as number} />,
        footer: (props) => (
          // @ts-ignore
          <CurrencyCell isFooterCell value={props.renderValue() as number} />
        ),
        size: 140,
        meta: {
          align: 'right',
          description: 'Amount spent to win Impressions',
          headerTitle: 'Media Cost ($)',
        },
      },
      {
        accessorKey: 'prebidCost',
        id: 'prebidCost',
        cell: (props) => <CurrencyCell value={props.renderValue() as number} />,
        footer: (props) => (
          // @ts-ignore
          <CurrencyCell isFooterCell value={props.renderValue() as number} />
        ),
        header: 'Pre-bid Cost',
        size: 140,
        meta: {
          align: 'right',
          headerTitle: 'Pre-bid Cost ($)',
          description: 'Amount spent to serve the impressions based on the pre-bid targeting',
        },
      },
    ],
  },
  {
    id: 'cost',
    header: 'Cost',
    columns: [
      {
        accessorKey: 'eCPM',
        id: 'eCPM',
        header: 'eCPM',
        size: 100,
        cell: (props) => <CurrencyCell value={props.renderValue() as number} />,
        footer: (props) => (
          // @ts-ignore
          <CurrencyCell isFooterCell value={props.renderValue() as number} />
        ),
        meta: {
          align: 'right',
          description: 'Effective Cost Per Mile is the amount you pay per one thousand Impressions',
        },
      },
      {
        accessorKey: 'eCPC',
        id: 'eCPC',
        header: 'eCPC',
        cell: (props) => <CurrencyCell value={props.renderValue() as number} />,
        footer: (props) => (
          // @ts-ignore
          <CurrencyCell isFooterCell value={props.renderValue() as number} />
        ),
        size: 100,
        meta: {
          align: 'right',
          description:
            'Effective Cost Per Click is the amount spend divided by the number of clicks',
        },
      },
      {
        accessorKey: 'eCPCV',
        id: 'eCPCV',
        header: 'CPCV',
        size: 100,
        cell: (props) => <CurrencyCell value={props.renderValue() as number} />,
        footer: (props) => (
          // @ts-ignore
          <CurrencyCell isFooterCell value={props.renderValue() as number} />
        ),
        meta: {
          align: 'right',
          description:
            'Cost Per Completed View is the amount spend divided by video ads that play through to completion',
        },
      },
    ],
  },
  {
    id: 'video',
    header: 'Video',
    columns: [
      {
        accessorKey: 'startCount',
        id: 'startCount',
        header: 'Video Start',
        size: 120,
        cell: (props) => <FormattedNumberCell value={props.renderValue() as number} />,
        footer: (props) => (
          // @ts-ignore
          <FormattedNumberCell isFooterCell value={props.renderValue()} />
        ),
        meta: {
          align: 'right',
          description: 'Number of video ads that start to play',
        },
      },
      {
        accessorKey: 'firstCount',
        id: 'firstCount',
        header: 'Video 25%',
        size: 120,
        cell: (props) => <FormattedNumberCell value={props.renderValue() as number} />,
        footer: (props) => (
          // @ts-ignore
          <FormattedNumberCell isFooterCell value={props.renderValue()} />
        ),
        meta: {
          align: 'right',
          description: 'Number of video ads that play through 25% of their duration',
        },
      },
      {
        accessorKey: 'midCount',
        id: 'midCount',
        header: 'Video 50%',
        size: 120,
        cell: (props) => <FormattedNumberCell value={props.renderValue() as number} />,
        footer: (props) => (
          // @ts-ignore
          <FormattedNumberCell isFooterCell value={props.renderValue()} />
        ),
        meta: {
          align: 'right',
          description: 'Number of video ads that play through 50% of their duration',
        },
      },
      {
        accessorKey: 'thirdCount',
        id: 'thirdCount',
        header: 'Video 75%',
        size: 120,
        cell: (props) => <FormattedNumberCell value={props.renderValue() as number} />,
        footer: (props) => (
          // @ts-ignore
          <FormattedNumberCell isFooterCell value={props.renderValue()} />
        ),
        meta: {
          align: 'right',
          description: 'Number of video ads that play through 75% of their duration',
        },
      },
      {
        accessorKey: 'completeCount',
        id: 'completeCount',
        header: 'Video 100%',
        size: 120,
        cell: (props) => <FormattedNumberCell value={props.renderValue() as number} />,
        footer: (props) => (
          // @ts-ignore
          <FormattedNumberCell isFooterCell value={props.renderValue()} />
        ),
        meta: {
          align: 'right',
          description: 'Number of video ads that play through 100% of their duration',
        },
      },
      {
        accessorKey: 'audioVideoViewed',
        id: 'audioVideoViewed',
        header: 'Hours Viewed',
        size: 140,
        cell: (props) => <HourCell value={props.renderValue() as number} />,
        footer: (props) => (
          // @ts-ignore
          <HourCell isFooterCell value={props.renderValue()} />
        ),
        meta: {
          align: 'right',
          description: 'Hours Viewed',
        },
      },
    ],
  },
  {
    id: 'conversion',
    header: 'Conversion',
    columns: [
      {
        accessorKey: 'totalAttributedConversion',
        id: 'totalAttributedConversion',
        header: 'Total Attributed Conversions',
        size: 220,
        cell: (props) => <FormattedNumberCell value={props.renderValue() as number} />,
        footer: (props) => (
          // @ts-ignore
          <FormattedNumberCell isFooterCell value={props.renderValue()} />
        ),
        meta: {
          align: 'right',
          description:
            'Total number of conversions that are attributed to a specific campaign based on the attribution model used',
        },
      },
      {
        accessorKey: 'totalAttributedViewThroughConversion',
        id: 'totalAttributedViewThroughConversion',
        header: 'Total Attributed View Through Conversions',
        size: 300,
        cell: (props) => <FormattedNumberCell value={props.renderValue() as number} />,
        footer: (props) => (
          // @ts-ignore
          <FormattedNumberCell isFooterCell value={props.renderValue()} />
        ),
        meta: {
          align: 'right',
          description:
            'Total number of conversions that occurred after a user viewed an ad but did not click on it',
        },
      },
      {
        accessorKey: 'totalAttributedClickThroughConversion',
        id: 'totalAttributedClickThroughConversion',
        header: 'Total Attributed Click Through Conversions',
        size: 300,
        cell: (props) => <FormattedNumberCell value={props.renderValue() as number} />,
        footer: (props) => (
          // @ts-ignore
          <FormattedNumberCell isFooterCell value={props.renderValue()} />
        ),
        meta: {
          align: 'right',
          description: 'Total number of conversions that occurred after a user clicked on an ad',
        },
      },
      {
        accessorKey: 'costPerAttributedConversion',
        id: 'costPerAttributedConversion',
        header: 'Cost Per Attributed Conversion (CPAC)',
        size: 300,
        cell: (props) => <FormattedNumberCell value={props.renderValue() as number} />,
        footer: (props) => (
          // @ts-ignore
          <FormattedNumberCell isFooterCell value={props.renderValue()} />
        ),
        meta: {
          align: 'right',
          description: 'Cost for one attributed conversion',
        },
      },
      {
        accessorKey: 'totalAttributedConversionRate',
        id: 'totalAttributedConversionRate',
        header: 'Total Attributed Conversion Rate',
        size: 280,
        cell: (props) => <FormattedNumberCell value={props.renderValue() as number} />,
        footer: (props) => (
          // @ts-ignore
          <FormattedNumberCell isFooterCell value={props.renderValue()} />
        ),
        meta: {
          align: 'right',
          description:
            'The percentage of users who interacted with an ad and completed a desired action (conversion) out of the total number of users who interacted with the ad.',
        },
      },
    ],
  },
];
